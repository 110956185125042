import httpClient from '@/api/httpClient';

import { USERS_ENDPOINT, ADMIN_PANEL_USERS_ENDPOINT } from '@/constants/endpoints';
import { DOCTOR_PHOTO_FILE_NAME } from '@/constants/users-page';

const getAllUsers = () => httpClient.get(`${USERS_ENDPOINT}/all`);
const getUserById = id => httpClient.get(`${ADMIN_PANEL_USERS_ENDPOINT}/${id}`);
const getUserByUsername = username => httpClient.get(`${USERS_ENDPOINT}?username=${username}`);
const createUser = userInfo => httpClient.post(`${ADMIN_PANEL_USERS_ENDPOINT}/create`, userInfo);

const updateUser = (id, userInfo) =>
  httpClient.patch(`${ADMIN_PANEL_USERS_ENDPOINT}/${id}`, userInfo);

const getUsersList = params => httpClient.post(`${ADMIN_PANEL_USERS_ENDPOINT}/findPaged`, params);
const deleteUser = userId => httpClient.delete(`${ADMIN_PANEL_USERS_ENDPOINT}/${userId}`);
const getUserSettings = userId => httpClient.get(`/api/settings?doctorId=${userId}`);

const updateDoctorSignature = ({ doctorId, doctorName }) => {
  const params = new URLSearchParams({ doctorName });

  return httpClient.post(`${USERS_ENDPOINT}/${doctorId}/saveSignature?${params}`);
};

const updateDoctorPhoto = ({ userId, photo }) => {
  const formData = new FormData();

  formData.append('photo', photo, DOCTOR_PHOTO_FILE_NAME);

  return httpClient.post(`/api/users/${userId}/savePhoto`, formData, {
    'content-Type': 'multipart/form-data'
  });
};

const deleteDoctorPhoto = ({ userId }) => httpClient.delete(`/api/users/${userId}/deletePhoto`);

const searchDoctorByName = searchName => httpClient.get(`/api/users/usersMinInfo/${searchName}`);

const addInfluencer = ({ doctorId: userId, influencerData }) =>
  httpClient.post('/api/influencer', { userId, ...influencerData});

const deleteInfluencer = influencerId => httpClient.delete(`/api/influencer/${influencerId}`);

const getInfluencers = doctorId => httpClient.get(`/api/influencer/users/${doctorId}/influencers`);

export {
  getUserByUsername,
  getUsersList,
  getUserById,
  createUser,
  updateUser,
  getAllUsers,
  deleteUser,
  getUserSettings,
  updateDoctorSignature,
  updateDoctorPhoto,
  deleteDoctorPhoto,
  searchDoctorByName,
  addInfluencer,
  getInfluencers,
  deleteInfluencer
};
