const USER_ROLE = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  DOCTOR: 'DOCTOR'
};

const USER_ROLE_LABEL = {
  [USER_ROLE.SUPER_ADMIN]: 'Superadmin',
  [USER_ROLE.ADMIN]: 'Admin',
  [USER_ROLE.DOCTOR]: 'Doctor'
};

export { USER_ROLE, USER_ROLE_LABEL };
