import Vue from 'vue';
import VueRouter from 'vue-router';

import LocalStorageService from '@/services/LocalStorageService';

import { USERS_PAGE } from '@/constants/users-page';
import { PROMO_CODE_TYPES } from '@/constants/promo-codes';
import { REGIONS_PAGES } from '@/constants/regions';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'SignIn',
    component: () => import('@/views/SignInPage'),
    meta: {
      guest: true
    }
  },
  {
    path: '/two-factor-authentication',
    name: 'TwoFactorAuthentication',
    component: () => import('@/views/TwoFactorAuthenticationPage'),
    meta: {
      guest: true
    },
    props: true
  },
  {
    path: '/recovery-code',
    name: 'RecoveryCode',
    component: () => import('@/views/RecoveryCodePage'),
    meta: {
      guest: true
    },
    props: true
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomePage'),
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard'
      },
      {
        path: '/products',
        name: 'Products',
        component: () => import('@/views/ProductsPage')
      },
      {
        path: '/products/:id',
        name: 'ProductDetails'
      },
      {
        path: '/orders',
        name: 'Orders',
        component: () => import('@/views/OrdersPage')
      },
      {
        path: '/orders/:id',
        name: 'OrderDetails',
        component: () => import('@/views/OrderDetailsPage')
      },
      {
        path: '/campaigns',
        name: 'Campaigns',
        component: () => import('@/views/MarketingCampaignsPage')
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import('@/views/UsersPage'),
        children: [
          {
            path: 'admins-and-doctors',
            name: USERS_PAGE.ADMINS_AND_DOCTORS,
            component: () => import('@/views/AdminsAndDoctorsPage')
          },
          {
            path: 'user/:id',
            name: USERS_PAGE.USER_DETAILS,
            component: () => import('@/views/UserDetailPage'),
            props: true
          },
          {
            path: 'customers',
            name: USERS_PAGE.CUSTOMERS,
            component: () => import('@/views/CustomersPage')
          },
          {
            path: 'customers/:id',
            name: USERS_PAGE.CUSTOMER_DETAILS,
            component: () => import('@/views/CustomerDetailPage'),
            props: true
          }
        ]
      },
      {
        path: '/promo-codes',
        name: 'PromoCodes',
        component: () => import('@/views/PromoCodesPage'),
        children: [
          {
            path: 'one-time',
            name: PROMO_CODE_TYPES.ONE_TIME,
            component: () => import('@/views/OneTimePromoCodePage')
          },
          {
            path: 'seasonal',
            name: PROMO_CODE_TYPES.SEASONAL,
            component: () => import('@/views/SeasonalPromoCodePage')
          }
        ]
      },
      {
        path: '/statistics-and-logs',
        name: 'StatisticsAndLogs',
        component: () => import('@/views/StatisticsAndLogs')
      },
      {
        path: '/scheduling-tasks',
        name: 'SchedulingTasks',
        component: () => import('@/views/SchedulingTasks')
      },
      {
        path: '/regions',
        name: 'Regions',
        component: () => import('@/views/RegionsPage'),
        children: [
          {
            path: 'countries',
            name: REGIONS_PAGES.COUNTRIES,
            component: () => import('@/views/CountriesPage')
          },
          {
            path: 'country/:id',
            name: REGIONS_PAGES.COUNTRY_DETAILS,
            component: () => import('@/views/CountryDetailsPage'),
            props: true
          },
          {
            path: 'logistic-centers',
            name: USERS_PAGE.LOGISTIC_CENTERS,
            component: () => import('@/views/logistic-centers/LogisticCentersPage')
          },
          {
            path: 'logistic-center/:id',
            name: USERS_PAGE.LOGISTIC_CENTER_DETAILS,
            component: () => import('@/views/logistic-centers/LogisticCenterDetailPage'),
            props: true
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const hasToken = !!LocalStorageService.getToken();

  if (to.matched.some(record => record.meta.guest)) {
    if (hasToken) {
      next('/');

      return;
    }

    next();

    return;
  }

  if (hasToken) {
    next();

    return;
  }

  next({ path: '/login', params: { redirect: to.fullPath } });
});

export default router;
