import { signIn } from '@/api/auth.api';
import { getUserByUsername } from '@/api/users.api';

import types from '@/store/types';

export default {
  async [types.actions.SIGN_IN](
    { commit },
    { username, password, twoFactorAuthenticationCode, recoveryCode }
  ) {
    try {
      const { data } = await signIn(username, password, twoFactorAuthenticationCode, recoveryCode);

      if (data && data.shouldUseTwoFactorAuthentication) {
        return { shouldUseTwoFactorAuthentication: true };
      }

      commit(types.mutations.SET_TOKEN, data.token);

      const { data: user } = await getUserByUsername(username);

      commit(types.mutations.SET_USER, user);

      return {};
    } catch (error) {
      return { error: error.message };
    }
  }
};
