const PROMO_CODE_TYPES = {
  ONE_TIME: 'One-time',
  SEASONAL: 'Seasonal',
  RENEW: 'Renew'
};

const PROMO_CODES_AMOUNT_TYPE = {
  ABSOLUTE: 'ABSOLUTE',
  PERCENTAGE: 'PERCENTAGE'
};

const PROMO_CODES_STATUS = {
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  DEACTIVATED: 'DEACTIVATED'
};

const PROMO_CODES_STATUS_VALUES = {
  [PROMO_CODES_STATUS.INACTIVE]: 'Inactive',
  [PROMO_CODES_STATUS.ACTIVE]: 'Active',
  [PROMO_CODES_STATUS.DEACTIVATED]: 'Deactivated'
};

const PROMO_CODE_TYPE_NAMES = Object.values(PROMO_CODE_TYPES);

const PROMO_CODES_SIDEBAR_ELEMENTS = {
  ONE_TIME: {
    title: PROMO_CODE_TYPES.ONE_TIME,
    pageName: PROMO_CODE_TYPES.ONE_TIME
  },
  SEASONAL: {
    title: PROMO_CODE_TYPES.SEASONAL,
    pageName: PROMO_CODE_TYPES.SEASONAL
  }
};

const PROMO_CODE_STATUS_CLASSES = {
  [PROMO_CODES_STATUS.ACTIVE]: 'green--text',
  [PROMO_CODES_STATUS.DEACTIVATED]: 'grey--text'
};

export {
  PROMO_CODE_TYPES,
  PROMO_CODES_SIDEBAR_ELEMENTS,
  PROMO_CODE_TYPE_NAMES,
  PROMO_CODES_STATUS,
  PROMO_CODES_STATUS_VALUES,
  PROMO_CODES_AMOUNT_TYPE,
  PROMO_CODE_STATUS_CLASSES
};
