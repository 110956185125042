import httpClient from '@/api/httpClient';

const signIn = (username, password, twoFactorAuthenticationCode, recoveryCode) =>
  httpClient.post('/auth/authenticateAdmin', {
    username,
    password,
    twoFactorAuthenticationCode,
    recoveryCode
  });

export { signIn };
