import getInitialState from '@/store/state';
import types from '@/store/types';

export default {
  [types.mutations.RESET_STORE](state) {
    Object.assign(state, getInitialState());
  },

  [types.mutations.SET_TOKEN](state, token) {
    state.token = token;
  },

  [types.mutations.SET_USER](state, user) {
    state.user = user;
  }
};
