import types from '@/store/types';
import { USER_ROLE } from '@/constants/user-roles';

export default {
  [types.getters.IS_SUPER_ADMIN]({ user }) {
    return user && user.role === USER_ROLE.SUPER_ADMIN;
  },

  [types.getters.USER_ID]({ user }) {
    return user && user.id;
  }
};
