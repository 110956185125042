import { STORAGE_KEY, BACKEND_VERSION_HEADER } from '@/constants/storage';

const LocalStorageService = {
  getToken() {
    const item = localStorage.getItem(STORAGE_KEY);

    if (!item) {
      return null;
    }

    const { token } = JSON.parse(item);

    return token || null;
  },

  clearStorage() {
    localStorage.removeItem(STORAGE_KEY);
  },

  setBackendVersion(version) {
    localStorage.setItem(BACKEND_VERSION_HEADER, version);
  },

  getBackendVersion() {
    const backendVersion = localStorage.getItem(BACKEND_VERSION_HEADER);
    
    try {
      return JSON.parse(backendVersion) ? backendVersion : null;
    } catch(error) {
      return null;
    }
  }
};

export default LocalStorageService;
