const USERS_PAGE = {
  ADMINS_AND_DOCTORS: 'AdminsAndDoctors',
  CUSTOMERS: 'Customers',
  LOGISTIC_CENTERS: 'LogisticCenters',
  USER_DETAILS: 'UserDetails',
  CUSTOMER_DETAILS: 'CustomerDetails',
  LOGISTIC_CENTER_DETAILS: 'LogisticCenterDetails'
};

const USERS_PAGES_NAMES = Object.values(USERS_PAGE);

const DOCTOR_PHOTO_FILE_NAME = 'doctorPhoto';

const MAX_PHOTO_SIZE_IN_BYTES = 15728640;
const ALLOWED_FILE_TYPE = 'image';

const PHOTO_VALIDATION_ERRORS = {
  TYPE_NOT_ALLOWED: 'typeNotAllowed',
  MAX_FILE_SIZE: 'maxFileSize'
};

export {
  USERS_PAGE,
  USERS_PAGES_NAMES,
  DOCTOR_PHOTO_FILE_NAME,
  MAX_PHOTO_SIZE_IN_BYTES,
  ALLOWED_FILE_TYPE,
  PHOTO_VALIDATION_ERRORS
};
