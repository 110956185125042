const USERS_ENDPOINT = '/api/users';
const ADMIN_PANEL_USERS_ENDPOINT = '/api/adminPanel/users';
const ENGINE_ENDPOINT = '/api/adminPanel/engine';
const PRODUCTS_ENDPOINT = '/api/adminPanel/product';
const ORDERS_ENDPOINT = '/api/adminPanel/orders';
const CUSTOMERS_ENDPOINT = '/api/adminPanel/customers';
const LOGISTIC_CENTER_ENDPOINT = '/api/adminPanel/logisticCenter';
const PATIENT_INVOICES_ENDPOINT = '/api/adminPanel/invoices';
const PATIENTS_ENDPOINT = '/api/patients';
const COUNTRY_ENDPOINT = '/api/adminPanel/country';
const INVOICE_ENDPOINT = '/api/fee/invoice';
const CLINICS_ENDPOINT = '/api/clinics';
const COUNTRIES_ENDPOINT = '/api/countries';

export {
  USERS_ENDPOINT,
  ADMIN_PANEL_USERS_ENDPOINT,
  PRODUCTS_ENDPOINT,
  ORDERS_ENDPOINT,
  ENGINE_ENDPOINT,
  LOGISTIC_CENTER_ENDPOINT,
  PATIENT_INVOICES_ENDPOINT,
  CUSTOMERS_ENDPOINT,
  PATIENTS_ENDPOINT,
  COUNTRY_ENDPOINT,
  INVOICE_ENDPOINT,
  CLINICS_ENDPOINT,
  COUNTRIES_ENDPOINT
};
