const types = {
  getters: {
    IS_SUPER_ADMIN: 'isSuperAdmin',
    USER_ID: 'userId'
  },
  mutations: {
    RESET_STORE: 'resetStore',
    SET_TOKEN: 'setToken',
    SET_USER: 'setUser'
  },
  actions: {
    SIGN_IN: 'signIn'
  }
};

export default types;
