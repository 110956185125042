import axios from 'axios';
import { pathOr } from 'ramda';

import LocalStorageService from '@/services/LocalStorageService';

import { STATUS_CODES } from '@/constants/errors';
import { BACKEND_VERSION_HEADER } from '@/constants/storage';

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const authInterceptor = config => {
  const updatedConfig = { ...config };
  const token = LocalStorageService.getToken();

  if (token) {
    updatedConfig.headers.Authorization = `Bearer ${token}`;
  }

  return updatedConfig;
};

const errorInterceptor = router => error => {
  const hasToken = !!LocalStorageService.getToken();
  const { response } = error;

  if (response && response.status === STATUS_CODES.UNAUTHORIZED && hasToken) {
    LocalStorageService.clearStorage();
    router.push('/login');
  }

  return Promise.reject(error);
};

const checkBackendVersionInterceptor = response => {
  const storageVersion = LocalStorageService.getBackendVersion();

  const responseVersion = pathOr(null, ['headers', BACKEND_VERSION_HEADER], response);

  if (!storageVersion) {
    LocalStorageService.setBackendVersion(responseVersion);
  }

  if (storageVersion && responseVersion && storageVersion !== responseVersion) {
    LocalStorageService.setBackendVersion(responseVersion);
    window.location.reload();
  }

  return Promise.resolve(response);
}

export const setupInterceptors = router => {
  httpClient.interceptors.request.use(authInterceptor);
  httpClient.interceptors.response.use(response => response, errorInterceptor(router));
  httpClient.interceptors.response.use(response => checkBackendVersionInterceptor(response));
};

export default httpClient;
