import Vue from 'vue';
import * as VueGoogleMaps from 'gmap-vue';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import { setupInterceptors } from '@/api/httpClient';

console.log(`app-google: ${process.env.VUE_APP_GOOGLE_MAP_KEY}`);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    libraries: 'places',
    language: 'en',
    installComponents: true
  }
});

Vue.config.productionTip = false;

setupInterceptors(router);

// eslint-disable-next-line vue/require-name-property
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
