import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import getInitialState from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

import { STORAGE_KEY } from '@/constants/storage';

Vue.use(Vuex);

const state = Object.assign({}, getInitialState());

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {},
  plugins: [
    createPersistedState({
      key: STORAGE_KEY
    })
  ]
});
