const REGIONS_PAGES = {
  COUNTRIES: 'Countries',
  COUNTRY_DETAILS: 'CountryDetails',
  LOGISTIC_CENTERS: 'LogisticCenters',
  LOGISTIC_CENTER_DETAILS: 'LogisticCenterDetails'
};

const REGIONS_TYPE_NAMES = Object.values(REGIONS_PAGES);

const REGIONS_SIDEBAR_ELEMENTS = {
  COUNTRIES: {
    title: 'Countries',
    pageName: REGIONS_PAGES.COUNTRIES
  },
  LOGISTIC_CENTERS: {
    title: 'Logistic centers',
    pageName: REGIONS_PAGES.LOGISTIC_CENTERS
  }
};

const DELIVERY_FLOW = {
  ODOO: 'ODOO',
  OVERNIA: 'OVERNIA',
  SMALL_MARKET: 'SMALL_MARKET',
  SEMI_ODOO_SMALL_MARKET: 'SEMI_ODOO_SMALL_MARKET',
  AL_MAJDOUIE: 'AL_MAJDOUIE'
};

export { REGIONS_PAGES, REGIONS_TYPE_NAMES, REGIONS_SIDEBAR_ELEMENTS, DELIVERY_FLOW };
